'use client';
import React, { useEffect, useState } from 'react';
import { usePWAInstall } from 'react-use-pwa-install';
import { MdOutlineInstallMobile } from "react-icons/md";

// Étendre l'interface Navigator pour inclure la propriété standalone
declare global {
    interface Navigator {
        standalone?: boolean;
    }
}

type Props = {};

const InstallButton = ({ }: Props) => {
    const install = usePWAInstall();
    const [isIOS, setIsIOS] = useState(false);
    const [isFirefox, setIsFirefox] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isInstalled, setIsInstalled] = useState(false);

    useEffect(() => {
        const userAgent = typeof window !== 'undefined' ? navigator.userAgent : '';
        setIsIOS(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);
        setIsFirefox(/Firefox/.test(userAgent));
        const standalone = window.matchMedia('(display-mode: standalone)').matches || navigator.standalone || false;
        setIsInstalled(standalone);
    }, []);

    const handleIOSInstallClick = () => {
        setShowModal(true);
    };

    const handleFirefoxInstallClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            {install && !isIOS && !isFirefox && !isInstalled && (
                <div className="flex items-center">
                    <button
                        className='flex items-center bg-red-600 hover:bg-red-700 text-white px-1 py-2 rounded'
                        onClick={install}
                    >
                        <MdOutlineInstallMobile className='me-1 text-xl' /><span className='text-xs'>Télécharger</span>
                    </button>
                </div>
            )}
            {isIOS && !isInstalled && (
                <div className="flex items-center">
                    <button
                        className='flex items-center bg-red-600 hover:bg-red-700 text-white px-1 py-2 rounded'
                        onClick={handleIOSInstallClick}
                    >
                        <MdOutlineInstallMobile className='me-1 text-xl' /><span className='text-xs'>Télécharger</span>
                    </button>
                </div>
            )}
            {isFirefox && !isInstalled && (
                <div className="flex items-center">
                    <button
                        className='flex items-center bg-red-600 hover:bg-red-700 text-white px-1 py-2 rounded'
                        onClick={handleFirefoxInstallClick}
                    >
                        <MdOutlineInstallMobile className='me-1 text-xl' /><span className='text-xs'>Télécharger</span>
                    </button>
                </div>
            )}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1500]">
                    <div className="bg-white p-4 rounded shadow-lg">
                        <h2 className="text-lg font-bold mb-2">Installer l&apos;application</h2>
                        {isIOS ? (
                            <p className='text-xs text-gray-700 mb-4'>
                                Pour installer cette application sur IOS, appuyez sur l&apos;icône de partage et sélectionnez &lsquo; Ajouter à l&apos;écran d&apos;accueil &rdquo;.
                            </p>
                        ) : (
                            <p className='text-xs text-gray-700 mb-4'>
                                FireFox ne prend pas en charge l&apos;installation de l&apos;application.
                                Veuillez utiliser un autre navigateur pour installer l&apos;application.
                            </p>
                        )}
                        <div className="flex justify-end">
                            <button
                                className='bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded'
                                onClick={closeModal}
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default InstallButton;