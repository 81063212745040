"use client"

import React, { useEffect, useState } from 'react'
import Links from './Links';
import { usePathname } from 'next/navigation';
import InstallButton from './InstallButton';

type Props = {

}

const MainMenuMobile = ({  }: Props) => {

    const [isOpen, setIsOpen] = useState(false);
    const pathname = usePathname()

    useEffect(() => {
        setIsOpen(false)
    }, [pathname])

    return (
        <div className='flex lg:hidden'>
            <InstallButton />
            <div className="lg:hidden z-[1000] ms-2">
                <button onClick={() => setIsOpen(!isOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {!isOpen ? (
                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    ) : (
                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    )}
                </button>
                <div className={`${isOpen ? 'absolute mt-2 left-0 right-0 bg-gray-800 z-50' : 'hidden'}`}>
                    <div className="flex-col px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <Links />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainMenuMobile